<template>
  <div>
    <b-modal
      id="hyperpay-payment-modal"
      v-model="showPaymentModal"
      size="lg"
      centered
      hide-footer
      hide-header
    >
      <div class="test-form"></div>
    </b-modal>
    <b-modal
      id="hyperpay-payment-modal"
      class="main-modal"
      size="lg"
      centered
      hide-footer
      hide-header
      v-if="hyperpayForm"
      @hidden="onModalHide"
    >
      <div class="test-form"></div>
    </b-modal>
    <b-modal
      id="add-payment"
      class="main-modal"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div
        class="d-flex justify-content-between align-content-center border-bottom modal-head"
      >
        <div class="d-flex align-items-center gap_2">
          <h3 class="title">إضافة رصيد للمحفظة</h3>
          <h6 class="subtitle">بطاقة ماستر كارد</h6>
        </div>
        <b-button
          @click="$bvModal.hide('add-payment')"
          class="border-0 bg-transparent p-0 m-0 back"
          >رجوع</b-button
        >
      </div>
      <div class="modal-body-content pr-0 pl-0">
        <div class="position-relative mb-1">
          <input-form
            name="add-balance"
            label="الرصيد المطلوب شحنه"
            placeholder="1000"
            v-model="deposit.amount"
            class="d-flex align-items-center add-balance-input gap_2"
          />
          <span class="sub-label">ريال سعودى</span>
        </div>
        <ul
          class="examples d-flex align-items-center justify-content-end gap_1 p-0 mb-5"
        >
          <li
            v-for="(item, index) in [100, 500, 1000, 2000]"
            :key="index"
            @click="examplesCheck(item)"
            :class="{ active: item === deposit.amount }"
            class="d-flex align-items-center justify-content-center"
          >
            {{ item }}
          </li>
        </ul>
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="save-modal-btn"
            @click.once="addFees"
            >شحن</b-button
          >
        </div>
      </div>
      <div class="test-form"></div>
    </b-modal>
    <b-row class="mb-5">
      <b-col sm="6" :md="fourGrid ? 6 : 6" v-if="ifLogin">
        <div class="fees-card">
          <b-button
            v-b-toggle.collapse-wallet
            class="fees-card-btn position-relative w-100 bg-transparent border-0"
          >
            <div class="fees-card-btn-inner">
              <img
                src="@/assets/images/ibbil/transportation/wallet.svg"
                alt=""
                class="img-fluid"
              />
              <span>المحفظة الالكترونية</span>
            </div>
          </b-button>
          <b-collapse id="collapse-wallet" class="fees-card-body">
            <form class="fees-card-form">
              <b-row>
                <b-col cols="12">
                  <label for="wallet-panels" class="input-with-icon-label mb-2"
                    >رصيد المحفظة</label
                  >
                </b-col>
                <b-col cols="7">
                  <div
                    class="d-flex align-items-center input-with-icon-container border-0 iq-border-radius-10 overflow-hidden"
                  >
                    <input
                      type="text"
                      name="wallet-panels"
                      aria-autocomplete="none"
                      disabled
                      v-model="balance"
                      id="wallet-panels"
                      class="form-control input-with-icon panels rounded-0 border-0"
                      autocomplete="off"
                    />
                    <div
                      class="icon d-flex align-items-center justify-content-center"
                    >
                      ر.س
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <b-button
                    class="border-0 h-100 wallet-add-btn w-100"
                    v-b-modal.add-payment
                  >
                    + إضافة رصيد
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <div class="mt-4">
                    <label for="password" class="input-with-icon-label mb-2"
                      >رمز المحفظة</label
                    >
                    <div
                      class="d-flex align-items-center input-with-icon-container iq-border-radius-10 overflow-hidden bg-transparent"
                    >
                      <input
                        autocomplete="off"
                        aria-autocomplete="none"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="wallet.password"
                        id="pin"
                        name="wallet-pin"
                        class="form-control input-with-icon rounded-0 border-0"
                        placeholder="رمز المحفظة"
                        pattern="\d*"
                        maxlength="5"
                      />
                      <div
                        class="icon d-flex align-items-center justify-content-center"
                        @click="togglePassword"
                      >
                        <i class="las la-eye-slash" v-if="!showPassword"></i>
                        <i class="las la-eye" v-else></i>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <div class="mt-4">
                    <b-button
                      :disabled="isProcessing"
                      @click="handlePayment"
                      variant="primary"
                      class="m-auto pay-btn d-flex align-items-center justify-content-center gap_1"
                    >
                      <span>دفع</span>
                      <i class="las la-angle-left icon"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </form>
          </b-collapse>
        </div>
      </b-col>
      <b-col sm="6" :md="fourGrid ? 6 : 6">
        <div class="fees-card h-100">
          <b-button
            v-b-toggle.collapse-master
            class="fees-card-btn position-relative w-100 bg-transparent border-0"
          >
            <div class="fees-card-btn-inner">
              <img
                src="@/assets/images/ibbil/transportation/master-card.svg"
                alt=""
                class="img-h"
              />
              <img
                src="@/assets/images/ibbil/payments/visa.svg"
                alt=""
                class="img-h"
              />
              <img
                src="@/assets/images/ibbil/payments/mada.svg"
                alt=""
                class="img-h"
              />
              <span>البطاقة الائتمانية</span>
            </div>
          </b-button>
          <b-collapse id="collapse-master" class="fees-card-body">
            <form class="fees-card-form pr-4">
              <b-row>
                <div
                  class="d-flex align-items-center justify-content-between w-100 px-5 mb-3"
                >
                  <img
                    src="@/assets/images/ibbil/transportation/master-card.svg"
                    alt=""
                    class="img-h"
                  />
                  <img
                    src="@/assets/images/ibbil/payments/visa.svg"
                    alt=""
                    class="img-h"
                  />
                  <img
                    src="@/assets/images/ibbil/payments/mada.svg"
                    alt=""
                    class="img-h"
                  />
                </div>
                <b-col cols="12">
                  <div class="mt-4">
                    <b-button
                      @click="$emit('payWithCard', { id: 1 })"
                      variant="primary"
                      class="m-auto pay-btn d-flex align-items-center justify-content-center gap_1"
                    >
                      <span>دفع</span>
                      <i class="las la-angle-left icon"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </form>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/*eslint-disable*/
import websiteServices from "@/modules/userProfile/services/userProfile";
import Payment from "@/Utils/payment";
import balanceCardVue from "../modules/userProfile/components/balanceCard.vue";
import btnWithIconVue from "../modules/userProfile/components/btnWithIcon.vue";
import PincodeInput from "vue-pincode-input";
export default {
  data() {
    return {
      ifLogin: localStorage.getItem("userToken"),
      balance: "",
      wallet: {
        panels: "",
        PIN_code: "",
      },
      deposit: {
        amount: "",
      },
      showPassword: false,
      isProcessing: false,
      hyperpayForm: false,
      showPaymentModal: false,
    };
  },
  components: { balanceCardVue, btnWithIconVue, PincodeInput },
  methods: {
    payWithMaster() {
      this.showPaymentModal = true;
    },
    async goToPayment(data) {
      sessionStorage.setItem("initiateData", JSON.stringify(data));
      const returnUrl = window.location.origin + "/payment-status";
      const Pay = new Payment(data.gateway.toLowerCase(), data);
      const url = await Pay.paymentConfig();

      if (data.gateway.toLowerCase() === "hyperpay") {
        this.hyperpayForm = true; // إظهار المودال

        window.wpwlOptions = {
          onReady: function () {
            console.log("HyperPay widget is ready");
          },
          onComplete: function (result) {
            console.log("Payment completed:", result);
          },
        };

        const script = document.createElement("script");
        script.src = url + data.checkout_id;
        script.onload = await function () {
          console.log("HyperPay script loaded");
        };
        document.body.appendChild(script);

        const form = document.createElement("form");
        form.classList.add("paymentWidgets");
        form.setAttribute("data-brands", "VISA MASTER AMEX");
        form.action = returnUrl;
        document.querySelector(".test-form").appendChild(form);

        this.addEscListener();
      }
    },
    addEscListener() {
      document.addEventListener("keydown", this.handleEscKey);
    },
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.hyperpayForm = false;
        document.removeEventListener("keydown", this.handleEscKey);
      }
    },
    onModalHide() {
      this.hyperpayForm = false;
    },
    addFees() {
      websiteServices.deposit(this.deposit).then((res) => {
        websiteServices.initiate(res.data).then((res) => {
          this.goToPayment(res.data);
        });
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    getWallaetDetails() {
      websiteServices.getWallaetDetails().then((res) => {
        this.balance = res.data.balance;
      });
    },
    examplesCheck(balance) {
      this.deposit.amount = balance;
    },
    handlePayment() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        this.$emit("payWithWallet", { id: 2, PIN_code: this.wallet.password });
        setTimeout(() => {
          this.isProcessing = false;
        }, 4000);
      }
    },
  },
  created() {
    this.getWallaetDetails();
  },
  mounted() {
    this.$on("payWithCard", this.payWithMaster); // الاستماع للحدث وإظهار المودال
  },
};
</script>

<style lang="scss">
.img-h {
  height: 25px;
}
.fees-card {
  background-color: #fff;
  box-shadow: 0px 3px 20px #0000000d;
  border-radius: 15px;
  .fees-card-btn {
    padding: 16px 20px !important;
    border-radius: inherit;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #494845 !important;
    .fees-card-btn-inner {
      width: 100%;
      white-space: nowrap;
      transition: 0.5s;
      img {
        min-width: 35px;
        margin-inline-end: 12px;
      }
    }
    &.not-collapsed {
      .fees-card-btn-inner {
        width: 0;
      }
      &::after {
        display: block;
      }
    }
    &::after {
      content: "";
      display: block;
      margin-top: 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      display: none;
    }
  }
  .fees-card-form {
    padding: 16px 20px;
  }
  .input-with-icon-label {
    font-size: 16px !important;
    color: #494845 !important;
    font-weight: 600 !important;
    margin-bottom: 8px !important;
  }
  .input-with-icon-container {
    background-color: #f7f7f7;
    height: 50px;
    border-radius: 7px !important;
    padding-inline-end: 20px;
    border: 1px solid rgba(73, 72, 69, 0.65);
    .input-with-icon {
      font-size: 16px;
      &.panels {
        height: 100%;
        background: transparent !important;
        flex: 1;
        padding-inline-start: 20px !important;
        color: #6ebf97;
        font-size: 25px;
        font-weight: 600;
        &:focus {
          color: #6ebf97;
        }
        &.border-0 {
          border: none !important;
        }
      }
    }
    .icon {
      color: #494845;
      font-size: 20px;
      min-width: auto;
    }
  }
  .wallet-add-btn {
    background-color: #d39d451a !important;
    color: #d39d45 !important;
    font-size: 16px !important;
    padding: 16px 11px !important;
  }
  .pay-btn {
    min-width: 165px;
    height: 50px;
    background-color: #3f6854 !important;
    border-radius: 8px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}
</style>